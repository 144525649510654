<template>
    <Layout class="container" :panels="panels" style="background-color: #01132C;" :padding="20" :gutter="20" :loading="loading">
      
    </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import moment from 'moment'
import UTIL from '@/utils'
moment.locale('zh-cn')

import {mapGetters} from 'vuex'
export default {
  name: "test1",
  components:{Layout},
  computed:{
    
    ...mapGetters('board',['config','loading','locations','recent_bid_projects','year_completed_projects','to_complete_projects','locations','current_contract_count','underControlProjects_count','total_inbuilding_count','progress_grouped_projects']),
    panels(){
      return [{
        x:0.6,y:0.3,w:8,h:1,
        control:"PanelCaption",
        data:{
          name:"项目分布情况",
          sub:"宁波高专成本合约系统数字化平台 v2.0.0"
        }
      },{
        x:14.5,y:0.3,w:9.5,h:1.5,control:"PanelTime"
      },
      {
        x:0.5,y:1.5,w:2.5,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"在建项目总数",
          border:8,
          value:this.total_inbuilding_count,
          toFixed:0,
          unit:"个" 
        }
      },
      {
        x:3,y:1.5,w:2.5,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"规模以上",
          border:8,
          value:this.current_contract_count,
          toFixed:0,
          unit:"个" 
        }
      },
      {
        x:5.5,y:1.5,w:2.5,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"规模以下",
          border:8,
          value:this.underControlProjects_count,
          toFixed:0,
          unit:"个" 
        }
      },
       {
        x:8,y:1.5,w:2.5,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"新开工项目",
          border:8,
          value:this.recent_bid_projects.length,
          toFixed:0,
          unit:"个" 
        }
      },
      {
        x:10.5,y:1.5,w:2.5,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"今年已竣工",
          border:8,
          value:this.year_completed_projects.length,
          toFixed:0,
          unit:"个" 
        }
      },
      {
        x:13,y:1.5,w:2.5,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"计划竣工(3个月内)",
          border:8,
          value:this.to_complete_projects.length,
          toFixed:0,
          unit:"个" 
        }
      },
      {
        x:0.5,y:2.7,w:3.75,h:3,
        control:"PanelDvChartPie",
        data:{
          name:"各事业部分布",
          border:8,
          value:this.to_complete_count,
          toFixed:0,
        }
      },
      {
        x:4.25,y:2.7,w:3.75,h:3,
        control:"PanelDvChartPie",
        data:{
          name:"各类型分布",
          border:8,
          value:this.config.to_complete_count,
          toFixed:0,
        }
      },
       {
        x:8,y:2.7,w:7.5,h:3,
        control:"PanelDvProjectPercent",
        data:{
          name:'当前在建项目产值进度分布',
          raw:this.progress_grouped_projects,
        }
      },
     
      {
        x:15.5,y:1.5,w:8.5,h:9.5,
        control:"PanelDvMap",
        data:this.locations
      },
      {
        x:0.5,y:5.7,w:5,h:5.3,control:"PanelDvProjects",data:{name:`新中标项目(半年内)`,raw:this.recent_bid_projects,border:12}
      },
      {
        x:5.5,y:5.7,w:5,h:5.3,control:"PanelDvProjects",data:{name:`2023年竣工项目`,raw:this.year_completed_projects,border:12}
      },
      {
        x:10.5,y:5.7,w:5,h:5.3,control:"PanelDvProjects",data:{name:`2023年将竣工项目`,raw:this.to_complete_projects,border:12}
      },
      ]
    }
  },
}
</script>